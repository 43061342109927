.Scroll-to-top-button {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    color: #202c33 !important;
    background-color: #798287 !important;
    opacity: 0.8;
    width: 3.5rem;
    height: 3.5rem;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Adiciona animação para o botão desaparecer */
@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

.Scroll-to-top-button.visible {
    animation-name: fadeIn;
}

.Scroll-to-top-button.hidden {
    animation-name: fadeOut;
}

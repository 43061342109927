:root {
    --dl-color-gray-500: #595959;
    --dl-color-gray-700: #D9D9D9;
    --dl-color-gray-900: #f8f8fa;
    --dl-color-white: #ffffff;
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-danger-300: #A22020;
    --dl-color-danger-500: #BF2626;
    --dl-color-danger-700: #E14747;
    --dl-color-gray-black: #000000;
    --dl-color-gray-white: #FFFFFF;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-color-primary-100: #003EB3;
    --dl-color-primary-300: #0074F0;
    --dl-color-primary-500: #14A9FF;
    --dl-color-primary-700: #85DCFF;
    --dl-color-success-300: #199033;
    --dl-color-success-500: #32A94C;
    --dl-color-success-700: #4CC366;
    --dl-size-size-xxlarge: 288px;
    --dl-size-size-maxwidth: 1400px;
    --dl-radius-radius-round: 50%;
    --dl-color-custom-accent1: #5D576B;
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-color-custom-primary1: #044d29;
    --dl-color-custom-primary2: #033c20;
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-color-custom-secondary1: #E6EBE0;
    --dl-color-custom-neutral-dark: #000000;
    --dl-color-custom-neutral-light: #FFFFFF;
    --dl-space-space-oneandhalfunits: 24px;
}

.App {
    text-align: center;
    background-color: var(--dl-color-gray-900);
}

.App-logo {
    height: 23vmin;
    pointer-events: none;
}

.App-header {
    background-color: var(--dl-color-gray-900);
    min-height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.Heading1 {
    font-size: 35px;
    text-align: center;
    font-weight: 700;
    line-height: 150%;
    color: var(--dl-color-custom-primary1);
    margin-bottom: 1rem;
}

.Heading2 {
    font-size: 25px;
    text-align: center;
    font-weight: 700;
    line-height: 150%;
    color: var(--dl-color-custom-primary1);
    padding-top: 0px;
    padding-left: 2rem;
}

.Home-faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 30rem;
    box-shadow: none;
    text-align: center;
    margin: 2rem;
}

.Accordion-main-page {
    background-color: var(--dl-color-gray-900) !important;
    box-shadow: none !important;
    text-align: justify !important;
    position: unset !important;
    width: 80%;
    min-width: 18rem;
    font-size: 18px;
}

.Accordion-main-page .MuiAccordionDetails-root {
    font-size: smaller;
    margin: 5px;
}

.Accordion-main-page .MuiAccordionSummary-expandIconWrapper {
    margin-left: 1rem;
}

.Home-footer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home-footer1 {
    background-color: #e6fdf2;
}

.Footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
}

.Home-container6 {
    gap: var(--dl-space-space-unit);
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.Home-container7 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.Home-icon-group1 {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.Home-icon-group1 a img {
    margin-right: 5px;
}

.Home-pasted-image1 {
    width: 410px;
    height: 144px;
}

.Home-pasted-image2 {
    width: 24px;
    height: 24px;
    text-decoration: none;
}

.Social-icons {
    fill: var(--dl-color-custom-neutral-dark);
}

.Home-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0px;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-left: 0px;
    border-color: var(--dl-color-gray-700);
    border-style: solid;
    border-width: 1px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

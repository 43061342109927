.Map-address-input {
    background-color: var(--dl-color-gray-white);
    width: 90%;
    border-radius:15;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.Map-address-input .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.Map-side-info {
    background-color: var(--dl-color-gray-900);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
}

/* .MuiButtonBase-root {
    margin-left: 5px !important;
} */

.Distribuidora-info {
    display: flex;
    border-radius: 8px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
    flex-direction: row;
    width: 90%;
    margin: auto !important;
    margin-bottom: 15px !important;
    cursor: pointer;
}

.Distribuidora-info-band {
    width: 5%;
    height: 100%;
    border-radius: 8px 0 0 8px !important;
}

.Distribuidora-info-content {
    width: 95%;
    height: 100%;
    padding: 1rem;
    color: var(--dl-color-custom-primary1);
    font-size: 15px;
}

.Distribuidora-info-content h3 {
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 5px;
    margin-top: 0px;
    padding-top: 0px;
}

.Distribuidora-info-content b {
    font-weight: 700;
    line-height: 150%;
    color: var(--dl-color-gray-black);
    margin-bottom: 10px;
}

.Distribuidora-info-accordion-details {
    padding: 1rem;
    color: var(--dl-color-custom-primary1);
    font-size: 15px;
    overflow: hidden;
}

.Distribuidora-info > div {
    width: 100% ;
}

.Cards-section {
    max-height: 30vh;
    min-height: 30vh;
    overflow-y: auto;
}

.Footer {
    position: fixed;
    width: 30%;
    margin-bottom: 10px;
    bottom: 2%;
    z-index: 1;
    background: var(--dl-color-gray-900);
}

.MuiFormControl-root {
    margin-top: 0.5rem !important;
}

.Map-rede-radio-group {
    margin-bottom: 1rem;
    color: black;
    font-size: 50px !important;
}

.leaflet-control-layers-overlays span {
    font-family: initial !important;
}

.Distribuidora-info-expand-icon {
    margin: auto;
    margin-right: 1rem;
    height: 2rem !important;
    width: 2rem !important;
    color: var(--dl-color-gray-500);
}

.Dialog-search-button {
    background-color: var(--dl-color-custom-primary1) !important;
    color: var(--dl-color-gray-white) !important;
    border-radius: 4px !important;
    font: 1rem "Roboto", sans-serif !important;
    padding: 0.5rem !important;
}

.Dialog-search-button:hover {
    background-color: var(--dl-color-custom-primary2) !important;
}

.Dialog-search-button:disabled {
    background-color: var(--dl-color-gray-500) !important;
    color: var(--dl-color-gray-white) !important;
}

.Dialog-cancel-button {
    color: var(--dl-color-custom-primary1) !important;
    border-radius: 4px !important;
    font: 1rem "Roboto", sans-serif !important;
    padding: 0.5rem !important;
}

.Contact-modal-box {
    display: flex;
    justify-content: center;
    text-align: center;
    color: var(--dl-color-custom-primary1);
    padding: 1rem;
    font: 700 1rem "Roboto", sans-serif !important;
    margin-bottom: 3rem !important;
    cursor: pointer;
}

.Timeout-message {
    position: absolute;
    top: calc(50% - 60px); /* Posiciona a mensagem no meio verticalmente */
    left: calc(50% - 100px); /* Posiciona a mensagem no meio horizontalmente */
    width: 200px;
    text-align: center;
    color: #000;
    font-size: 14px;
    opacity: 0; /* Começa com a opacidade 0 para a animação de fade-in */
    animation: fade-in 0.5s ease-in-out forwards; /* Animação de fade-in */
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media (max-width: 1220px) {
    .Footer {
        width: 35vw !important;
    }

    .Cards-section {
        max-height: 25vh;
        min-height: 25vh;
    }

    .Contact-modal-box {
        margin-bottom: 1rem !important;
    }
}

@media (max-width: 992px) {
    .Footer {
        bottom: 5%;
        width: 30vw !important;
        position: absolute;
    }

    .Map-rede-radio-group {
        justify-content: center;
    }
}

@media (max-width: 900px) {
    .Footer {
        display: flex;
        align-self: center;
        width: 50vw;
    }
}

.Search-input-and-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    width: 100%;
}

.Address-input {
    background-color: var(--dl-color-gray-white);
    width: 40%;
    min-width: 15rem;
    margin: 2rem;
    margin-bottom: 0.5rem;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
}

.Address-input .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
}

.Search-button {
    background-color: var(--dl-color-custom-primary1) !important;
    color: var(--dl-color-gray-white) !important;
    text-transform: none !important;
    font: 400 16px/1.5 "Roboto", sans-serif !important;
    padding: 10px 20px !important;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    margin: 2rem;
    width: 15rem;
    box-shadow: none !important;
}

.Search-button:hover {
    background-color: var(--dl-color-custom-primary2) !important;
}

.Spinner-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 1000;
    margin: 0 !important;
}

.MuiCircularProgress-circle {
    color: var(--dl-color-custom-primary1) !important;
}

.Error-overlay {
    color: red;
    font-size: 1rem;
    margin-bottom: 2rem;
    margin-top: -1rem;
}

.Radio-group {
    margin-bottom: 1rem;
    color: black;
}
